import {
	isCommunicationActive,
	isCreativeCanvasStationActive,
	isDefenseActive,
	isPowerActive,
	isRepairsActive,
	isScannerActive,
	isTractorBeamActive,
} from '../store/selectors/jrState'
import {
	isThrustersActive,
	isTransporterActive,
	getFullState,
	getActiveStudents,
	getRanActionIds,
} from '../store/selectors/sharedSelectors'
import { isGivenStationInQuestionPhase } from '../components/StationUpgradeOverlay/selectors'
import { isCommunicationActive as communicationPlusIsActive } from '../store/selectors/jrPlusState/communication'
import { isDefenseActive as defensePlusIsActive } from '../store/selectors/jrPlusState/defense'
import { isPowerActive as powerPlusIsActive } from '../store/selectors/jrPlusState/power'
import { isTractorBeamActive as tractorBeamPlusIsActive } from '../store/selectors/jrPlusState/tractorBeam'
import config from '../config'

import { getIsJuniorPlus, getMissionType, getStation, getStudentId } from '../store/stores/general'
import type { ReduxStore } from '../store/rootReducer'
import type { StationEnum, JrStationEnum, JrPlusStationEnum } from '../types'
import { getPhaseFromMission, isJrPlusRepairsActive } from '@mission.io/mission-toolkit'
import type { BothFullMissionStates } from '@mission.io/mission-toolkit'
import {
	MISSION_TYPES,
	MISSION_PHASES,
	QUESTION_PHASE,
	COLLABORATIVE_CULMINATING_MOMENT,
} from '@mission.io/mission-toolkit/constants'
import { getGameActionById } from '../store/stores/staticData'
import { getQuestionPhase } from '../store/selectors/jrPlusState/questionData'
import { getCanvasId } from '../components/CreativeCanvas/connect/selectors'
import type { Action } from '@mission.io/mission-toolkit/actions'

import {
	MissionCompleteIcon,
	LiteracyEventIcon,
	QuestionSlotIcon,
	CulminatingMomentIcon,
	BriefingIcon,
} from '../images/timeline'
import { CreativeCanvasIcon } from '../images/jrPlusPanel'
import {
	ThrustersIcon,
	CommunicationsIcon,
	DefenseIcon,
	PowerIcon,
	RepairsIcon,
	SensorsIcon,
	TractorBeamIcon,
	TransporterIcon,
} from '../images/jrPanel/index'
import { ONE_MINUTE } from '../constants'

// A map of `StationEnum` to a function that returns whether the station is active or not
export const stationToGetStationActivityStatus = {
	// JR stations
	communication: isCommunicationActive,
	defense: isDefenseActive,
	power: isPowerActive,
	repairs: isRepairsActive,
	scanner: isScannerActive,
	thrusters: (store: ReduxStore): boolean => !getIsJuniorPlus(store) && isThrustersActive(store),
	tractorBeam: isTractorBeamActive,
	transporter: (store: ReduxStore): boolean =>
		!getIsJuniorPlus(store) && isTransporterActive(store),

	// JR Plus stations
	creativeCanvas: isCreativeCanvasStationActive,
	decks: (store: ReduxStore): boolean => isJrPlusRepairsActive(store.jrPlusState),
	communicationPlus: communicationPlusIsActive,
	defensePlus: defensePlusIsActive,
	powerPlus: powerPlusIsActive,
	thrustersPlus: (store: ReduxStore): boolean => getIsJuniorPlus(store) && isThrustersActive(store),
	tractorBeamPlus: (store: ReduxStore): boolean =>
		getIsJuniorPlus(store) && tractorBeamPlusIsActive(store.jrPlusState.stationData.tractorBeam),
	transporterPlus: (store: ReduxStore): boolean =>
		getIsJuniorPlus(store) && isTransporterActive(store),
	sensors: (store: ReduxStore): boolean => true,
}

/**
 * gets the current station activity from the state
 *
 * @param { state } ReduxStore the redux state to grab the current activity from
 * @return { { [stationId: string]: boolean } } a mapping of stationIds to weather the station is active or not
 **/
export function getStationActivity(state: ReduxStore): { [stationId: StationEnum]: boolean } {
	const activity: { [stationId: StationEnum]: boolean } = {}
	PRIORITIZED_4PLUS_LIST.forEach(station => {
		activity[station] = stationToGetStationActivityStatus[station](state)
	})
	PRIORITIZED_K3_LIST.forEach(station => {
		activity[station] = stationToGetStationActivityStatus[station](state)
	})
	if (config.devFlags?.stationDevIsWorkingOn) {
		activity[config.devFlags?.stationDevIsWorkingOn] = true
	}
	return activity
}

export const PRIORITIZED_4PLUS_LIST: JrPlusStationEnum[] = [
	'thrustersPlus',
	'tractorBeamPlus',
	'defensePlus',
	'decks',
	'communicationPlus',
	'transporterPlus',
	'powerPlus',
	'creativeCanvas',
	'sensors',
]

export const PRIORITIZED_K3_LIST: JrStationEnum[] = [
	'thrusters',
	'repairs',
	'power',
	'scanner',
	'defense',
	'communication',
	'tractorBeam',
	'transporter',
]

export const successMessages = {
	thrusters: 'Navigation Completed',
	repairs: 'Repairs Completed',
	transporter: 'Transport Completed',
	communication: 'Communication Completed',
	defense: 'Defense Completed',
	tractorBeam: 'Tractor Beam Completed',
	power: 'Power Change Completed',
	scanner: 'Scanning Completed',
	// if we want to add jrPlus station messages, they go here as well
}

export function getStudentValue<T>(state: ReduxStore, map: IdMap<T>): ?T {
	const studentId = getStudentId(state.general)
	if (studentId) {
		return map[studentId]
	}
	return null
}

/**
 * Gets the prioritized active station that a student is allowed to click on and use.
 * @param {ReduxStore} state
 * @return {StationEnum}
 */
export function getPrioritizedStation(state: ReduxStore): ?StationEnum {
	const missionType = getMissionType(state)
	const currentStation = getStation(state)
	const stationActivityMap = getStationActivity(state)
	if (missionType === MISSION_TYPES.FOUR_PLUS) {
		// If the current station is active and it's not the sensors station, allow directing back to sensors screen
		if (currentStation !== 'sensors' && (!currentStation || stationActivityMap[currentStation])) {
			return 'sensors'
		}
	}
	const highestPriorityActiveStations = getActiveStationsByPriority(state)
	// The highest priority active station that is not the user's current station
	const stationToSwitchTo = highestPriorityActiveStations.find(
		station => station !== currentStation
	)
	if (stationToSwitchTo) {
		return stationToSwitchTo
	}
	return missionType === MISSION_TYPES.FOUR_PLUS ? 'sensors' : currentStation
}

/**
 * Gets a list of all active stations in order of priority.
 */
function getActiveStationsByPriority(state: ReduxStore): $ReadOnlyArray<StationEnum> {
	const missionType = getMissionType(state)
	const stationActivityMap = getStationActivity(state)
	if (missionType === MISSION_TYPES.FOUR_PLUS) {
		return PRIORITIZED_4PLUS_LIST.filter(station => {
			return stationActivityMap[station] || isGivenStationInQuestionPhase(state, station)
		})
	} else {
		return PRIORITIZED_K3_LIST.filter(station => {
			return stationActivityMap[station]
		})
	}
}

export type FrameStatus = 'LOBBY' | 'ACTIVE' | 'EXIT'
export const FRAME_STATUS = {
	LOBBY: 'LOBBY',
	ACTIVE: 'ACTIVE',
	EXIT: 'EXIT',
}

/**
 * Returns the status of the frame based on the current phase of the mission.
 * @param {ReduxStore} state redux store
 * @returns {?FrameStatus} the frame status
 */
export function getFrameStatus(state: ReduxStore): FrameStatus | null {
	const missionPhase = getPhaseFromMission(getFullState(state))
	if (!missionPhase) {
		return null
	}
	return missionPhase === MISSION_PHASES.LOGIN || missionPhase === MISSION_PHASES.TRAINING
		? FRAME_STATUS.LOBBY
		: missionPhase === MISSION_PHASES.ACTIVE
		? FRAME_STATUS.ACTIVE
		: missionPhase === MISSION_PHASES.SURVEY_STANDBY ||
		  missionPhase === MISSION_PHASES.REVIEW_RESULTS ||
		  missionPhase === MISSION_PHASES.CLOSED
		? FRAME_STATUS.EXIT
		: null
}

type CulminatingMomentInfoForTeacherTips = {|
	prompt: string,
	media: [] | [{| type: 'IMAGE', url: string |}],
	options: Array<{|
		id: string,
		text: ?string,
		correct: boolean,
	|}>,
|}

// Info to display in the teacher tips box
type TeacherTipsData = {|
	name: string,
	icon: React$ComponentType<{ className?: string }>,
	culminatingMomentQuestion?: CulminatingMomentInfoForTeacherTips,
	teacherTips: Array<string>,
	sentenceFrames?: Array<string>,
|}

/**
 * Converts an array of strings into an unordered markdown list
 */
function toMarkdownList(...items: Array<string>): string {
	return items.map(item => `* ${item}`).join('\n')
}

// tips information to use when there is an active map
const GENERIC_MAP_TIPS = {
	teacherTips: [
		toMarkdownList(
			'Encourage students to scan everything, and then discuss it as teams.',
			'The more students scanning an object, the faster it will go.'
		),
	],
	sentenceFrames: [
		'Command team, let everyone know what to scan first.',
		'Talk about the data as a team before we talk as a group.',
	],
}

/**
 * An ordered array of functions that return teacher tips data based on the current state of the mission. The first function that returns data will be the data
 * displayed to the teacher. The higher priority teacher tips come first so that we show the most relevant information to the teacher. For example, if the defense
 * station is active and the mission is ended, we only want to show the teacher tips for the mission end, therefore it is earlier in the array.
 */
const TEACHER_TIPS_GETTERS: Array<
	(arg: { store: ReduxStore, fullState: BothFullMissionStates }) => TeacherTipsData | void
> = [
	// Mission End
	({ fullState }) => {
		if (
			fullState.missionPhase === MISSION_PHASES.REVIEW_RESULTS ||
			fullState.missionPhase === MISSION_PHASES.SURVEY_STANDBY ||
			fullState.missionPhase === MISSION_PHASES.CLOSED
		) {
			return {
				name: 'Mission End',
				icon: MissionCompleteIcon,
				teacherTips: [
					toMarkdownList(
						'Discuss the mission (highlights, address knowledge gaps, etc.).',
						'Be sure to point out things you observed in watching the class work together (both good and bad).',
						'You can scroll to view questions and click questions for more detail.',
						'Training is available on dashboard to learn more about each skill metric.',
						'Intervention and extension activities available on analytics dashboard after the mission.',
						'Reward high performing students with a RALFie award (available in teacher resources folder).'
					),
				],
				sentenceFrames: [
					'I want to point out one thing I was really excited to see, and another I feel we can definitely improve in our next mission.',
				],
			}
		}
	},
	// Mission Failure
	({ store, fullState }) => {
		if (fullState.death.status !== 'ALIVE') {
			const isJrPlus = getIsJuniorPlus(store)
			return {
				name: 'Failure',
				icon: CulminatingMomentIcon,
				teacherTips: isJrPlus
					? [
							toMarkdownList(
								'Discuss key moments that led to the failure.',
								'We like failure. If they think failure is bad, try giving them an inspirational speech about why they are failures for being so wrong about failure.'
							),
					  ]
					: [
							toMarkdownList(
								'Discuss any mistakes made and help come up with a new plan.',
								"Let students know you have a magic button that pops them right back into the mission, and you'll push that as soon as they convince you they are ready."
							),
					  ],

				sentenceFrames: ['Let’s come up with a plan before jumping back in.'],
			}
		}
	},
	// Active Culminating Moment/Investigation
	({ store, fullState }) => {
		const currentCulminatingMomentScreenId =
			fullState.launchpad.screen?.type === 'CULMINATING_MOMENT'
				? fullState.launchpad.screen._actionId
				: null
		const currentInvestigationCulminatingMomentId =
			fullState.investigations[0]?.culminatingMomentActionId

		const cmToUseForTeacherTips =
			currentCulminatingMomentScreenId || currentInvestigationCulminatingMomentId

		const culminatingMomentAction = cmToUseForTeacherTips
			? getGameActionById(store, cmToUseForTeacherTips)
			: null

		if (culminatingMomentAction?.type === 'CULMINATING_MOMENT_SCREEN') {
			const isJrPlus = getIsJuniorPlus(store)
			let teacherTips = isJrPlus
				? [
						toMarkdownList(
							'If needed, give students extra time to discuss before moving on.',
							'Encourage teams to explain/justify their choice.'
						),
				  ]
				: [
						toMarkdownList(
							'Discuss the question with the class and call on students to explain their choice.'
						),
				  ]
			let sentenceFrames = isJrPlus
				? ['Engineering team, explain what you saw in the data that supports your choice.']
				: ['Why do you think one option might be better than another?']

			// We are not on a culminating moment screen. See if we should add teacher tips for a map
			if (!currentCulminatingMomentScreenId) {
				if (fullState.map) {
					teacherTips = GENERIC_MAP_TIPS.teacherTips
					sentenceFrames = GENERIC_MAP_TIPS.sentenceFrames
				}
			}

			return {
				name: currentCulminatingMomentScreenId ? 'Decision Moment' : 'Investigation',
				icon: CulminatingMomentIcon,
				culminatingMomentQuestion: {
					prompt: culminatingMomentAction.questionInfo.phrase,
					media: culminatingMomentAction.questionInfo.media[0]
						? [culminatingMomentAction.questionInfo.media[0]]
						: [],
					options: culminatingMomentAction.questionInfo.options.map(option => ({
						id: option._id,
						text: option.text,
						correct: option.correct,
					})),
				},
				teacherTips: [
					...teacherTips,
					...culminatingMomentAction.teacherTips.map(tip => tip.text),
					// $FlowFixMe[incompatible-type] if there's a map, we expect it to be a Map type. This would be fixed by making full state types exact
					...(fullState.map && fullState.map.description ? [fullState.map.description] : []),
				],
				sentenceFrames,
			}
		}
	},
	// Collaborative Culminating Moment
	({ fullState }) => {
		if (
			fullState.launchpad.screen?.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' &&
			fullState.launchpad.screen?.currentStep.status !==
				COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING
		) {
			return {
				name: 'Teacher Review',
				icon: CreativeCanvasIcon,
				teacherTips: [
					toMarkdownList(
						'Ask each team to present/explain their work.',
						'Provide feedback and use the rubric to evaluate each team’s submission.'
					),
				],
				sentenceFrames: [
					'Why did you go with this approach?',
					'How is this option better than others?',
				],
			}
		}
	},
	// Jr Question Slot
	({ fullState }) => {
		if (fullState.launchpad.screen?.type === 'QUESTION_SLOT') {
			return {
				name: 'Quiz Question',
				icon: QuestionSlotIcon,
				teacherTips: [
					toMarkdownList('Read the quiz question and answers out loud to your students.'),
				],
				sentenceFrames: ['* Answer your own questions, but let’s read through it together...'],
			}
		}
	},
	// Literacy Event
	({ store }) => {
		const literacyEventIsActive = getActiveStudents(store).some(
			({ id: studentId }) =>
				getFullState(store).literacyEvents.studentStates[studentId]?.literacyEventsStates[0]
		)
		if (literacyEventIsActive) {
			return {
				name: 'Incoming Information',
				icon: LiteracyEventIcon,
				teacherTips: [
					toMarkdownList(
						'Students must complete the task(s) on the screen before they’ll be able to participate in the rest of the mission.',
						'These tasks are intentional scaffolding for the mission’s objectives. Encourage students to analyze the information on their screens carefully.'
					),
				],
				sentenceFrames: [
					'Pay close attention to what you’re reading, (*ominously*) it may come in handy later on.',
				],
			}
		}
	},
	// Station Upgrades, aka Question Phase
	({ store }) => {
		const questionPhase = getQuestionPhase(store)

		if (questionPhase.status === QUESTION_PHASE.STATUS.ACTIVE) {
			const questionCount = questionPhase.questionIds.length
			return {
				name: `Quiz Questions (${questionCount})`,
				icon: QuestionSlotIcon,
				teacherTips: [
					toMarkdownList(
						'More correct questions = more awesome station.',
						'All students have to answer their questions before the station starts.'
					),
				],
				sentenceFrames: [
					'Remember, the more questions you get right the better the station will work.',
				],
			}
		}
	},
	// Jr Plus Station-specific teacher tips
	...PRIORITIZED_4PLUS_LIST.map(station => {
		// Teacher tips for maps are also handled with Culminating Moments/Investigations above
		if (station === 'sensors') {
			return ({ fullState }) => {
				if (!fullState.map) {
					return
				}
				return {
					name: 'Investigation',
					icon: CulminatingMomentIcon,
					// $FlowFixMe[incompatible-use] if there's a map, we expect it to be a Map type. This would be fixed by making full state types exact
					teacherTips: [...GENERIC_MAP_TIPS.teacherTips, fullState.map.description],
					sentenceFrames: GENERIC_MAP_TIPS.sentenceFrames,
				}
			}
		}
		// Creative Canvas station has custom teacher tips
		if (station === 'creativeCanvas') {
			return ({ store }) => {
				const currentCanvasId = getCanvasId(store)
				const currentCreativeCanvasAction: ?Action<string> = currentCanvasId
					? getGameActionById(store, currentCanvasId)
					: null
				if (
					currentCreativeCanvasAction?.type === 'ACTIVATE_STATION' &&
					currentCreativeCanvasAction.stationData.stationId === 'CREATIVE_CANVAS'
				) {
					const issues = currentCreativeCanvasAction.stationData.issues || [
						currentCreativeCanvasAction.stationData.issue,
					]
					const teacherTips = [
						toMarkdownList(
							'Encourage students to make a plan before starting (paying careful attention to the rubric and data).',
							'Move around the room to ask teams about their plan and provide feedback/encouragement.'
						),
					]
					const tipsFromIssues = issues
						.flatMap(issue => issue.teacherTips.map(tip => tip.text.trim()))
						.join('\n')
					if (tipsFromIssues) {
						teacherTips.push(tipsFromIssues)
					}
					return {
						name: 'Collaboration Station',
						icon: CreativeCanvasIcon,
						teacherTips,
						sentenceFrames: [
							"Remember, I'll be scoring your work in front of everyone, so give me lots of reasons to let you succeed.",
						],
					}
				}
			}
		}
		return ({ store }) => {
			if (stationToGetStationActivityStatus[station](store)) {
				return STATION_TEACHER_TIPS_DATA[station]
			}
		}
	}),
	// Jr Station-specific teacher tips
	...PRIORITIZED_K3_LIST.map(station => {
		return ({ store }) => {
			if (stationToGetStationActivityStatus[station](store)) {
				return STATION_TEACHER_TIPS_DATA[station]
			}
		}
	}),
	// Mission Startup
	({ store, fullState }) => {
		if (fullState.launchpad.visitedPhasesAndCheckpoints.length !== 1) {
			return
		}
		const firstCheckpoint = fullState.launchpad.visitedPhasesAndCheckpoints[0]
		// Caveat 1: The initial screen action must be marked as a checkpoint or phase
		// Caveat 2: This selector will only run when the redux store changes, *not as time passes*. This means
		// it's possible to see these tips for longer than 2 minutes
		if (
			getRanActionIds(store)[0] === firstCheckpoint._actionId &&
			// only show for the first 2 minutes after the checkpoint
			firstCheckpoint.timestamp + 2 * ONE_MINUTE >= Date.now()
		) {
			return {
				name: 'Mission Briefing',
				icon: BriefingIcon,
				teacherTips: [
					toMarkdownList(
						'The Mission Briefing gives students important information about the Mission.',
						'Help students stay focused so they can understand the Mission objectives.'
					),
				],
				sentenceFrames: [
					'Listen closely! This information will be important later in the Mission.',
				],
			}
		}
	},
]

const STATION_TEACHER_TIPS_DATA = {
	// JR stations
	communication: {
		name: 'Communications',
		icon: CommunicationsIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap anywhere on the screen to send a signal.',
				'Hitting the satellite when it’s in the middle = success.'
			),
		],
		sentenceFrames: ['Make sure you time it right, don’t just fire willy nilly!'],
	},
	defense: {
		name: 'Defense',
		icon: DefenseIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap anywhere on the screen to fire.',
				'Hitting the object when it’s in the middle = success.'
			),
		],
		sentenceFrames: ['Hit it as many times as you can before time runs out!'],
	},
	power: {
		name: 'Power',
		icon: PowerIcon,
		teacherTips: [
			toMarkdownList(
				'Power levels are an average of the entire class.',
				'Encourage students to move their power bar up/down, and communicate on what level they think they should try.',
				'Arrow will point to green when they reach the correct power level.'
			),
		],
		sentenceFrames: [
			'Looks like we aren’t quite there, let’s get a few more people to raise their power level a bit more.',
		],
	},
	repairs: {
		name: 'Repairs',
		icon: RepairsIcon,
		teacherTips: [toMarkdownList('Click and drag the robot to the blinking red dots.')],
		sentenceFrames: ['See how many dots you can repair before time runs out!'],
	},
	scanner: {
		name: 'Scanner',
		icon: SensorsIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap anywhere on the circle when the line reaches the highlighted portion.',
				'Getting it inside the pizza slice = success.'
			),
		],
		sentenceFrames: ['Make sure you time it right! Wait for the line to reach the pizza slice.'],
	},
	thrusters: {
		name: 'Navigation',
		icon: ThrustersIcon,
		teacherTips: [
			toMarkdownList(
				'The class must steer together (the more involved = better it will work).',
				'Click and drag the glowing dot in the direction you want to go.',
				'Help guide them on what direction to go.'
			),
		],
		sentenceFrames: ['We need to work together!'],
	},
	tractorBeam: {
		name: 'Tractor Beam',
		icon: TractorBeamIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap anywhere on the screen to send out the tractor beam.',
				'Hitting the object when it’s in the middle will pull it in.'
			),
		],
		sentenceFrames: ['Grab as many as you can before time runs out!'],
	},
	transporter: {
		name: 'Transporter',
		icon: TransporterIcon,
		teacherTips: [
			toMarkdownList(
				'Transportation will complete when enough dots have been connected by the class.',
				'Click and drag one dot to another of the same color to make a connection.'
			),
		],
		sentenceFrames: ['Connect as many dots as you can before time runs out!'],
	},

	// JR Plus stations
	decks: {
		name: 'Repairs',
		icon: RepairsIcon,
		teacherTips: [
			toMarkdownList(
				'To get repair tasks, click the bouncing robot then click on a damaged room (red).',
				'Some tasks are solo, and others must be done with someone else.',
				'Encourage students to get up and move around if needed.'
			),
		],
		sentenceFrames: ['If you have a task with someone else, get up and go find them!'],
	},
	communicationPlus: {
		name: 'Communications',
		icon: CommunicationsIcon,
		teacherTips: [
			toMarkdownList(
				'Enter an angle in the text box to aim at the satellites, then click the button to send the signal.',
				'Students can use the "enter" key to send signals faster.'
			),
		],
		sentenceFrames: ['The signal won’t connect unless we hit enough satellites.'],
	},
	defensePlus: {
		name: 'Defense',
		icon: DefenseIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap anywhere on the screen to fire.',
				'The more questions they got right, the faster their station will fire and more damage it will do.'
			),
		],
		sentenceFrames: ['You can only fire one at a time, so make it count!'],
	},
	powerPlus: {
		name: 'Power',
		icon: PowerIcon,
		teacherTips: [
			toMarkdownList(
				'The average power level is based on the levels of the entire class.',
				'The indicator will turn green when the students find the optimal power level.'
			),
		],
		sentenceFrames: ['Everyone work together to find the right power level.'],
	},
	thrustersPlus: {
		name: 'Navigation',
		icon: ThrustersIcon,
		teacherTips: [
			toMarkdownList(
				'The class must steer together (the more involved = better it will work).',
				'Click and drag the glowing dot in the direction you want to go.',
				'Encourage the Command Team to take charge.'
			),
		],
		sentenceFrames: ['Command team, give us some guidance!'],
	},
	tractorBeamPlus: {
		name: 'Tractor Beam',
		icon: TractorBeamIcon,
		teacherTips: [
			toMarkdownList(
				'Click/tap any square on the grid to send out the tractor beam.',
				'The more questions they got right, the faster the beam will go.'
			),
		],
		sentenceFrames: ['Make sure you time it right!'],
	},
	transporterPlus: {
		name: 'Transporter',
		icon: TransporterIcon,
		teacherTips: [
			toMarkdownList(
				'Transportation will complete when enough dots have been connected by the class.',
				'Click and drag one dot to another of the same color to make a connection.'
			),
		],
		sentenceFrames: [
			'When you make a connection, new dots will appear - so make as many as you can!',
		],
	},
}

/**
 * Gets the data to display in the teacher tips box.
 */
export function getTeacherTipsData(store: ReduxStore): TeacherTipsData | null {
	const fullState = getFullState(store)

	for (const getTeacherTips of TEACHER_TIPS_GETTERS) {
		const teacherTipsData = getTeacherTips({ store, fullState })
		if (teacherTipsData) {
			return teacherTipsData
		}
	}

	return null
}
