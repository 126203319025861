import React from 'react'
import { getActiveStudentCount } from '../../../../../store/stores/general'
import { useSelector, useDispatch } from 'react-redux'
import { getMultiplier, getStudentStatus } from '../../../../../store/selectors/jrPlusState/sensor'
import { sendMessage } from '../../../../../store/stores/webSocket'
import styled from 'styled-components/macro'
import ActionButton from './ActionButton'
import { DeltaClockProgressBar } from '../../../../../components/DeltaClock'
import { getStudentId } from '../../../../../store/stores/general'
import { useAdvanceTraining } from '../../../../../store/selectors/jrPlusState/training'
import type { ObjectInfo as ObjectInformation } from '@mission.io/mission-toolkit'
import type { InfoForOtherActions } from '../../helpers/constants'

// After a map object is selected, this component will display info about the map object and allow a student to scan/stop scanning the object
export default function Content({
	name,
	subtitle,
	objectInfo,
	objectId,
	totalScanningStudents,
	scanComplete,
	infoForOtherActions,
}: {
	name: string,
	subtitle: ?string,
	objectInfo: ObjectInformation,
	objectId: string,
	totalScanningStudents: number,
	infoForOtherActions: Array<InfoForOtherActions>,
	scanComplete: boolean,
}): React$Node {
	const dispatch = useDispatch()
	const studentId = useSelector(state => getStudentId(state.general))
	const activated = Boolean(objectInfo.actionData.SCAN.students[studentId ?? ''])
	const totalStudents = useSelector(getActiveStudentCount)
	const isStudentScanning = useSelector(state =>
		studentId ? getStudentStatus(state, studentId)?.scanning : false
	)
	const advanceTraining = useAdvanceTraining()

	const onClick = (e, type, cancel) => {
		e.stopPropagation()
		dispatch(
			sendMessage('SENSOR_OPTION_MESSAGE', {
				objectId,
				type,
				cancel,
			})
		)
		if (advanceTraining) {
			advanceTraining()
		}
	}

	// Note: currentMultiplier and nextMultiplier do not take boost into account
	const currentMultiplier = (getMultiplier(totalScanningStudents, totalStudents) * 100).toFixed()
	return (
		<Container>
			<div className="title">{name}</div>
			{subtitle && <div>{subtitle}</div>}
			{totalScanningStudents > 0 && (
				<>
					<DeltaClockProgressBar
						includeText
						timerId={objectInfo.scanTimerId}
						progressColor="#01FEFF"
						backgroundColor="#020322"
						className="scan-progress title"
						height="30px"
						width="100%"
					/>
					<div>
						Scanning:{' '}
						<span
							css={`
								color: #01feff;
							`}>
							{totalScanningStudents} / {totalStudents}
						</span>
						&nbsp;-- {currentMultiplier}% speed
					</div>
				</>
			)}
			{!scanComplete &&
				(activated ? (
					<button
						className="rounded py-0.5 px-2 mx-auto hover:bg-primary-green/75 font-game-headline text-[8px] bg-error"
						onClick={e => onClick(e, 'SCAN', true)}>
						STOP SCAN
					</button>
				) : (
					<button
						onClick={e => onClick(e, 'SCAN', false)}
						className="rounded py-0.5 px-2 mx-auto hover:bg-primary-green/75 font-game-headline text-[8px] bg-primary-green">
						{isStudentScanning ? 'SWITCH SCAN' : 'SCAN'}
					</button>
				))}

			{infoForOtherActions.length > 0 && (
				<div css="display: flex; width: 100%; justify-content: flex-end">
					{infoForOtherActions.map(info => (
						<ActionButton
							info={info}
							key={info.key}
							onClick={(e, cancel) => onClick(e, info.key, cancel)}
						/>
					))}
				</div>
			)}
		</Container>
	)
}

const Container = styled.div.attrs({
	className: 'text-center w-full h-full p-2.5 text-white flex flex-col gap-[3px]',
})`
	font-size: 6px;
	.scan-progress {
		border: 2px solid #020322;
		border-radius: 6px;
	}
	.title {
		font-size: 8px;
	}
`
