import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	getActiveHealthTask,
	getAssignedSurveyPrompt,
} from '../../store/selectors/jrPlusState/healthTask'
import { getStudentHealth } from '../../store/stores/general'
import { sendMessage } from '../../store/stores/webSocket'
import { MODAL_Z_INDEX } from '../../constants/styles'
import { TaskInfo, ChooseType, SurveyResponse } from './components'
import { CustomModal } from '../Controller/Dialogs/CustomModal.jsx'

const noop = () => {}

export default function HealthTask(): React$Node {
	const activeTask = useSelector(getActiveHealthTask)
	const surveyPrompt = useSelector(getAssignedSurveyPrompt)
	const health = useSelector(getStudentHealth)
	const dispatch = useDispatch()
	const close = () => {
		if (health === 0) {
			return
		}
		if (activeTask?.id) {
			dispatch(sendMessage('CANCEL_HEALTH_TASK', activeTask.id))
		}
	}
	if (!activeTask && !surveyPrompt) return null
	return (
		<>
			{activeTask && (
				<CustomModal
					isOpen
					frameContent
					overlayStyle={{ zIndex: MODAL_Z_INDEX }}
					onClose={health !== 0 ? close : noop}>
					{activeTask.type ? <TaskInfo task={activeTask} /> : <ChooseType task={activeTask} />}
				</CustomModal>
			)}
			{surveyPrompt && (
				<CustomModal isOpen={true} frameContent overlayStyle={{ zIndex: MODAL_Z_INDEX + 1 }}>
					<SurveyResponse prompt={surveyPrompt} />
				</CustomModal>
			)}
		</>
	)
}
