import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import {
	CLASS_QUESTION_INDEX,
	GRADIENT_DARK_BLUE,
	GRADIENT_LIGHT_GREEN,
	GRADIENT_LIGHT_PURPLE,
	GRADIENT_DARK_PURPLE,
} from '../../constants/styles'
import {
	getClassQuestionInfo,
	getCulminatingMomentVote,
} from '../../store/selectors/sharedSelectors'
import Vote from './Vote'
import CanvasVote from './CanvasVote'
import Toolkit from '../Frame/JrPlusFrame/components/Toolkit'
import { toolkitTabTypes } from '../Frame/JrPlusFrame/components/Toolkit/Toolkit'
import { getCollaborativeCulminatingMomentScreen, getStudentId } from '../../store/stores/general'
import { getScreenTimerForCulminatingMoment } from '../../store/selectors/sharedSelectors'
import { TimeLeftNumbersOnly } from '../DeltaClock'
import { FaHourglass } from 'react-icons/fa'
import type { QuestionInfo, CreativeCanvasStationCriterion } from '@mission.io/mission-toolkit'
import { COLLABORATIVE_CULMINATING_MOMENT } from '@mission.io/mission-toolkit/constants'
import { getCanvasId, getCanvasTeams, getIssueContent } from '../CreativeCanvas/connect/selectors'
import type { ReduxStore } from '../../store/rootReducer'
import { getMyTeam } from '../../store/selectors/jrPlusState/teams'
import MarkdownTextToSpeech from '../Markdown/MarkdownTextToSpeech'

/**
 * A view to display a culminating moment question to the class. Supports a multi-document collaborative culminating moment or a multiple choice class question.
 * @returns {React$Node}
 */
export default function ClassQuestionView(): React$Node {
	const tabs = [toolkitTabTypes.TEXT_TO_SPEECH_SETTINGS]
	const culminatingMomentData = useCulminatingMomentType()

	return (
		<Wrapper>
			<Toolkit tabs={tabs} />
			{culminatingMomentData ? (
				<QuestionWrapper>
					<Header>
						<MarkdownTextToSpeech
							disabledComponents={['a', 'img']}
							className="prose-on-game-light-purple prose-2xl">
							{culminatingMomentData.phrase}
						</MarkdownTextToSpeech>
					</Header>
					{culminatingMomentData.type === 'COLLABORATIVE' ? (
						<CanvasVote
							collaborativeCulminatingMoment={culminatingMomentData}
							selectedDocumentId={
								culminatingMomentData.qualifiedDocuments.find(
									({ documentId }) => culminatingMomentData.votedOptionId === documentId
								)?.documentId
							}
						/>
					) : (
						<Vote
							currentQuestion={culminatingMomentData.currentQuestion}
							selectedOptionId={
								culminatingMomentData.currentQuestion.options.find(
									option => option._id === culminatingMomentData.votedOptionId
								)?._id
							}
						/>
					)}
				</QuestionWrapper>
			) : (
				<h1 className="text-8xl text-center h-full flex items-center">Critical Decision</h1>
			)}
		</Wrapper>
	)
}

/**
 * The header for the Culminating Moment vote screen. Renders a countdown for time left in the culminating moment, along with the passed in children.
 */
function Header({ children }: { children: React$Node }): React$Node {
	const screenTimerId = useSelector(getScreenTimerForCulminatingMoment)
	return screenTimerId ? (
		<div className="flex text-white mb-6">
			<h2
				className="absolute left-10 flex items-center"
				style={{
					zIndex: CLASS_QUESTION_INDEX + 1,
				}}>
				<FaHourglass css="margin-right: 8px" /> <TimeLeftNumbersOnly timerId={screenTimerId} />
			</h2>
			<div className="w-52" />
			<div>{children}</div>
		</div>
	) : (
		<div className="flex text-white mb-6 justify-center mx-6">{children}</div>
	)
}

export const Wrapper: StyledType<> = styled.div`
	height: 100%;
	position: relative;
	background: linear-gradient(to bottom, ${GRADIENT_LIGHT_PURPLE}, ${GRADIENT_DARK_PURPLE});
	z-index: ${CLASS_QUESTION_INDEX};
`

const QuestionWrapper: StyledType<> = styled.div`
	height: 100%;
	z-index: ${CLASS_QUESTION_INDEX};
	padding: 20px 0;
	display: grid;
	grid-template-rows: auto 1fr;
	background: linear-gradient(to right, ${GRADIENT_LIGHT_GREEN}, ${GRADIENT_DARK_BLUE});
`

type MultipleChoiceCulminatingMomentType = {
	type: 'MULTIPLE_CHOICE',
	currentQuestion: QuestionInfo,
	votedOptionId: ?string,
	phrase: string,
}
export type CollaborativeCulminatingMomentType = {
	type: 'COLLABORATIVE',
	qualifiedDocuments: { documentId: string, teamId: string }[],
	votes: { students: { [string]: { documentIds: string[] } } },
	votedOptionId: ?string,
	phrase: string,
	criteria: Array<CreativeCanvasStationCriterion>,
	canvasStationId: string,
	grades: {
		documents: {
			[documentId: string]: {
				criteria: {
					[criteriaId: string]: { selectedOption: string },
				},
			},
		},
	},
}

type CulminatingMomentType =
	| MultipleChoiceCulminatingMomentType
	| CollaborativeCulminatingMomentType

/**
 * A hook that returns data about the current culminating moment and how the student is voting on it.
 * @returns {CulminatingMomentType} The data about the current culminating moment:
 * 			- type: The type of culminating moment (multiple choice or collaborative)
 * 			- currentQuestion: The current question being voted on (only for multiple choice culminating moments)
 * 			- collaborativeVotePhase: The current collaborative vote phase (only for collaborative culminating moments)
 * 			- votedOptionId: The id of the option the student voted for
 * 			- phrase: The phrase for the culminating moment
 * 			- criteria: The criteria for the culminating moment (only for collaborative culminating moments)
 * 			- data: The data for the culminating moment (only for collaborative culminating moments)
 */
function useCulminatingMomentType(): CulminatingMomentType | void {
	return useSelector((state: ReduxStore) => {
		const currentQuestion: ?QuestionInfo = getClassQuestionInfo(state)
		if (currentQuestion) {
			const votedAnswerId = getCulminatingMomentVote(state)
			return {
				type: 'MULTIPLE_CHOICE',
				currentQuestion,
				votedOptionId: votedAnswerId,
				phrase: currentQuestion.phrase,
			}
		}
		const collaborativeCulminatingMomentScreen = getCollaborativeCulminatingMomentScreen(state)
		const collaborativeVotePhase = collaborativeCulminatingMomentScreen?.currentStep
		if (
			collaborativeCulminatingMomentScreen &&
			collaborativeVotePhase &&
			collaborativeVotePhase.status === COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.VOTING
		) {
			const creativeCanvasId = getCanvasId(state)
			const myTeam = getMyTeam(state)
			const myId = getStudentId(state.general)
			if (!creativeCanvasId || !myTeam || !myId) return
			const canvasIssueData = getIssueContent({ canvasId: creativeCanvasId, teamId: myTeam.id })(
				state
			)
			const qualifiedDocuments = new Set(
				collaborativeVotePhase.qualifiedDocuments.map(({ documentId }) => documentId)
			)
			const canvasTeams = getCanvasTeams(state)

			const votedDocumentIds = collaborativeVotePhase.votes.students[myId]?.documentIds

			if (!canvasIssueData) {
				return
			}
			return {
				type: 'COLLABORATIVE',
				qualifiedDocuments: (canvasTeams || []).filter(team =>
					qualifiedDocuments.has(team.documentId)
				),
				votes: collaborativeVotePhase.votes,
				votedOptionId: votedDocumentIds?.[votedDocumentIds.length - 1],
				phrase: canvasIssueData.prompt,
				criteria: canvasIssueData.criteria,
				canvasStationId: creativeCanvasId,
				grades: collaborativeCulminatingMomentScreen.scores,
			}
		}
	})
}
