import React from 'react'
import { useSelector } from 'react-redux'
import { Name } from '../../../../basics'
import styled from 'styled-components/macro'
import { getStation, getStudentName } from '../../../../../store/stores/general'
import { getCurrentStudentPoints } from '../../../../../store/selectors/sharedSelectors'
import { getStationActivity } from '../../../../../utility/selectors'
import { useSpring, animated } from '@react-spring/web'
import classnames from 'classnames'
import StationSelector from '../../../StationSelector'

export default function Footer(props: { className?: string }): React$Node {
	const stationActivity = useSelector(getStationActivity)
	const currentStation = useSelector(getStation)
	const inactive = currentStation ? !stationActivity[currentStation] : false
	return (
		<FooterContainer {...props}>
			<Corner>
				<Points />
			</Corner>
			<CenterBase>
				<StudentName />
			</CenterBase>

			<StationContainer>
				<div className="positioner">
					<StationSelector height="100%" width="auto" />
				</div>
				<div className="background" />
			</StationContainer>
			<div
				className={classnames(
					'-z-[2] absolute w-full h-full flex',
					inactive && 'bg-primary-800/90'
				)}>
				<div className="h-[60%] w-full bg-primary-600 my-auto mb-0" />
			</div>
		</FooterContainer>
	)
}

const Corner = ({ children }: { children: React$Node }) => {
	return (
		<CornerContainerStyle>
			<CornerInnerStyle>{children}</CornerInnerStyle>
		</CornerContainerStyle>
	)
}

const StudentName = () => {
	const name = useSelector(getStudentName)

	return (
		<div css="background-color: var(--primary-color-darker); display: flex;">
			<div
				css={`
					background-color: var(--color-primary-darker);
					flex: 1;
					height: 100%;
					min-width: 40vw;
					display: flex;
					border-radius: ${BORDER_RADIUS + 8}px ${BORDER_RADIUS + 8}px 0px 0px;
				`}>
				<Name value={name || 'Unknown'} css="text-align: center; width: 100%; margin: auto;" />
			</div>
		</div>
	)
}

// Displays and animates students points as the points increment.
const Points = () => {
	const points = useSelector(getCurrentStudentPoints)
	const { number: totalPointsSpring } = useSpring({ number: points })

	return (
		<PointContainer>
			<PointMarker />
			<animated.div className="point-value">
				{totalPointsSpring.to(
					x => `${x.toLocaleString(undefined, { maximumFractionDigits: 0 })} PTS`
				)}
			</animated.div>
		</PointContainer>
	)
}

const PointContainer = styled.div`
	height: 100%;
	background-color: var(--color-primary-darkest);
	border-radius: 0px 12px 12px 0px;
	display: flex;

	padding-left: 16px;
	align-items: center;
	.point-value {
		margin-left: 16px;
		color: white;
		font-family: Orbitron, sans-serif;
	}
`

const PointMarker = styled.div`
	height: 40%;
	aspect-ratio: 1 / 1;
	background-color: #00ffff;
	border-radius: 40px;
`
const BORDER_RADIUS = 16

const StationContainer = styled.div`
	height: 150%;
	width: 20%;
	position: relative;
	.background {
		position: absolute;
		bottom: 0;
		background-color: var(--color-primary-darker);
		height: 66.67%;
		width: 100%;
		border-top-left-radius: ${BORDER_RADIUS + 8}px;
		z-index: -1;
	}
	.positioner {
		z-index: 1;
		height: 100%;
		width: fit-content;
		float: right;
	}
`

const CenterBase = styled.div`
	flex: 1;
	height: 100%;

	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
`

const FooterContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	position: relative;
`

const CornerInnerStyle = styled.div`
	background-color: var(--color-primary-dark);
	height: 100%;
`

const CornerContainerStyle = styled.div`
	background-color: var(--color-primary-darker);
	height: 100%;
	padding: 10px;
	position: relative;

	width: 20%;
	&:first-of-type {
		border-top-right-radius: ${BORDER_RADIUS + 8}px;
		padding-left: 0;
		${CornerInnerStyle} {
			border-top-right-radius: ${BORDER_RADIUS}px;
			border-bottom-right-radius: ${BORDER_RADIUS}px;
			padding: 4px 4px 4px 0px;
		}
	}
	&:nth-of-type(3) {
		border-top-left-radius: ${BORDER_RADIUS + 8}px;
		padding-right: 0;
		${CornerInnerStyle} {
			border-top-left-radius: ${BORDER_RADIUS}px;
			border-bottom-left-radius: ${BORDER_RADIUS}px;
			padding: 4px 0px 4px 4px;
		}
	}
`
