import React from 'react'
import { useTeam } from '../../../../store/selectors/jrPlusState/teams'
import Canvas from '../../Canvas'
import type { JuniorTeam } from '@mission.io/mission-toolkit'
import { CreativeCanvasFullScreen } from './CreativeCanvasFullScreen'

/* A card to display a single team's canvas within a grid of other canvases. Displays the canvas and the team name. Clicking on the card will select the canvas and cause it to be fullscreen.
 * @param {string} props.teamId the team id of the team whose canvas is being displayed
 * @param {string} props.documentId the documentId that represents the canvas created
 */
export function CanvasCardForWatchingEdits({
	teamId,
	documentId,
	canvasId,
	className,
}: {
	teamId: string,
	documentId: string,
	canvasId: string,
	className?: string,
}): React$Node {
	const [isFullScreenOpen, setIsFullScreenOpen] = React.useState(false)
	const team = useTeam(teamId)
	return (
		<button
			className={className}
			onClick={() => {
				setIsFullScreenOpen(true)
			}}>
			<TeamName team={team} />

			<Canvas
				documentId={documentId}
				isTeacher
				readOnly
				className="[--canvas-border-radius:theme('spacing.3')] [--canvas-border-width:theme('spacing.2')]"
			/>
			{isFullScreenOpen && (
				<CreativeCanvasFullScreen
					isOpen
					onClose={e => {
						e.stopPropagation()
						setIsFullScreenOpen(false)
					}}
					teamId={teamId}
					documentId={documentId}
					canvasId={canvasId}
				/>
			)}
		</button>
	)
}

// Show Team name above the canvas
function TeamName({ team }: { team: ?JuniorTeam }): React$Node {
	if (!team) return null
	return (
		<div className="flex-1 items-center flex z-20 pb-2 rounded-lg">
			<img src={team.icon} className="size-6" alt="" />
			<h3 className="mr-8 ml-4 text-sm">{team.displayName}</h3>
		</div>
	)
}
