import React, { useState } from 'react'
import type { AbstractComponent, Node } from 'react'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components/macro'
import { APP_WHITE } from '../../constants/styles'
import classnames from 'classnames'
import Loading from './Loading'
import type { StyledComponent } from 'styled-components'

export const CloseButton: StyledType<> = styled(MdClose)`
	position: absolute;
	cursor: pointer;
	color: ${APP_WHITE};
	margin: 8px;
	top: 0;
	right: 0;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.25);
	}
`
type ButtonProps = {
	$small?: boolean,
	children: React$Node,
	className?: string,
	// todo: merge $variant and variant
	$variant?: 'link',
	// todo: remove temp variant
	variant?: 'danger' | 'secondary' | 'temp',
	outline?: boolean,
	as?: 'a',
}
/**
 * The standard button to use throughout the app.
 * The button needs to support ref forwarding because
 * Button is a custom component and can be the child of react-tiny-popover's popover component.
 * See https://www.npmjs.com/package/react-tiny-popover#migrating-from-versions-3-and-4
 */
export const Button = (React.forwardRef(
	(
		{
			$small,
			children,
			className,
			$variant,
			variant,
			as: HtmlTag = 'button',
			outline,
			...props
		}: ButtonProps,
		ref
	): React$Node => {
		const classesForVariant = (() => {
			if ($variant === 'link') {
				return {
					base: 'border-none !shadow-none underline hover:enabled:text-accent-blue',
				}
			} else if (variant === 'danger') {
				return {
					base: 'border-error',
					fill: 'bg-error text-white',
					outline: 'text-error',
				}
			} else if (variant === 'secondary') {
				return {
					base: 'border-accent-orange',
					fill: 'bg-accent-orange text-white',
					outline: 'text-accent-orange',
				}
			} else if (variant === 'temp') {
				return {
					base: 'border-primary-700',
					fill: 'bg-primary-700 text-white',
					outline: 'text-primary-700',
				}
			} else {
				return {
					base: 'border-primary-green',
					fill: 'bg-primary-green text-white',
					outline: 'text-primary-green',
				}
			}
		})()
		return (
			<HtmlTag
				{...props}
				ref={ref}
				className={classnames(
					className,
					'shadow transition min-w-24 font-game-headline tracking-wide uppercase border-2 cursor-pointer',
					// disabled styles
					'disabled:cursor-not-allowed disabled:shadow-none disabled:transform-none disabled:opacity-50',
					// interaction styles
					'hover:brightness-90 active:brightness-75',
					// size dependent styles
					$small ? 'py-1 px-2 rounded-sm text-xs' : 'p-3 rounded-md text-base',
					classesForVariant.base,
					outline ? classesForVariant.outline : classesForVariant.fill
				)}>
				{children}
			</HtmlTag>
		)
	}
): AbstractComponent<ButtonProps, HTMLButtonElement | HTMLAnchorElement>)

/**
 * A basic button with the primary color of the app. It is fully rounded and has a hover effect.
 * Is not a styled component and uses tailwind classes.
 * @param {React$Node} children any children you want to have appear in the button
 * @param {string} className any additional classes you want to add to the button
 * @returns
 */
export const RoundedPrimaryButton = ({
	children,
	outline,
	...props
}: {
	outline?: boolean,
	children?: React$Node,
	className?: string,
}): React$Node => {
	const solidColor = 'bg-primary-600 text-white hover:brightness-125'
	const outlineColor =
		'bg-transparent text-primary-300 border-primary-300 border-2 hover:bg-primary-300/25'
	return (
		<button
			{...props}
			className={classnames(
				'whitespace-nowrap rounded-full py-1 px-4 transition-all disabled:cursor-not-allowed disabled:opacity-20',
				outline ? outlineColor : solidColor,
				props.className
			)}>
			{children}
		</button>
	)
}

export function SecondaryButton(props: {
	$small?: true,
	children: Node,
	onClick: () => void,
}): React$Node {
	return <Button {...props} variant="secondary" />
}

/**
 * A button that only allows one click to be made on it.
 * @param {CustomOneClickType} props attributes for the button element
 * @returns {React$Node} A button element
 */
export const OneClickButton = (
	props: ButtonProps & { onClick: (event: MouseEvent) => mixed }
): React$Node => {
	const [clicked, setClicked] = useState(false)
	const { onClick, children, ...rest } = props
	const _onClick = (event: MouseEvent) => {
		if (clicked) {
			return
		}
		setClicked(true)
		onClick(event)
	}
	return (
		<Button {...rest} disabled={clicked} onClick={_onClick}>
			{clicked ? <Loading className="size-6" /> : children}
		</Button>
	)
}

/**
 * A button with almost nothing visible. Should be used to convert an icon into a button.
 *
 * Usage: <IconButton onClick={...}><MdClose /></IconButton>
 */
export const IconButton: StyledComponent<
	{
		children: Node,
		onClick: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,
	},
	mixed,
	mixed
> = styled.button.attrs({
	className: 'text-white hover:scale-110 transition-all duration-200 ease-in-out',
})`
	background-color: transparent;
	border: none;
	padding: 0;
	margin: 0;

	svg {
		pointer-events: none;
	}
`
