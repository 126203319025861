// @flow
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPendingStudents } from '../../../store/stores/general'
import { CustomModal } from './CustomModal'
import { sendMessage } from '../../../store/stores/webSocket'
import styled from 'styled-components/macro'
import type { PendingStudent } from '../../../store/stores/general'
import { Button } from '../../basics/Buttons.jsx'

/**
 * A modal that appears when students try to join the mission. Only shows one pending student request at a time. If the teacher accepts the student,
 * they are asked if they want to permanently add the student to the class.
 */
export function PendingStudentsModal(): React$Node {
	const [studentsBeingAccepted, setStudentsBeingAccepted] = useState<Array<PendingStudent>>([])
	const [shownNaughtyNames, setShownNaughtyNames] = useState({})
	const pendingStudents = useSelector(getPendingStudents)
	const dispatch = useDispatch()

	if (pendingStudents.length === 0) {
		return null
	}

	const buttons = [
		<Button
			onClick={() => {
				setStudentsBeingAccepted(pendingStudents)
			}}>
			{'Accept' + (pendingStudents.length > 1 ? ' All' : '')}
		</Button>,
	]

	if (pendingStudents.length === 1) {
		buttons.push(
			<Button
				onClick={() => {
					dispatch(
						sendMessage('PENDING_STUDENT_RESPONSE', {
							response: 'reject',
							pendingStudentRequestIds: [pendingStudents[0].pendingRequestId],
						})
					)
				}}>
				Reject
			</Button>
		)
	}

	function getDisplayName(student: PendingStudent) {
		return student.nameIsNaughty && !shownNaughtyNames[student.pendingRequestId]
			? student.name
					.split(' ')
					.map(namePart => {
						return new Array(namePart.length).fill('*').join('')
					})
					.join(' ')
			: student.name
	}

	return (
		<>
			<CustomModal
				className="text-base flex flex-col"
				isOpen
				title={
					(pendingStudents.length === 1 ? 'A student is' : 'Students') +
					' waiting to join the mission'
				}
				buttons={buttons}>
				{pendingStudents.length === 1 ? (
					(() => {
						const pendingStudent = pendingStudents[0]
						const isHidingNaughtyName =
							pendingStudent.nameIsNaughty && !shownNaughtyNames[pendingStudent.pendingRequestId]
						return (
							<>
								{pendingStudent.nameIsNaughty && (
									<div className="italic mb-2">
										We believe the student provided name may include inappropriate language. The
										potentially inappropriate name is visible on the computer of the student who
										submitted it.
									</div>
								)}
								<p>
									Name: <b>{getDisplayName(pendingStudents[0])}</b>
									{isHidingNaughtyName && (
										<ShowNaughtyNameButton
											onClick={() =>
												setShownNaughtyNames({
													...shownNaughtyNames,
													[pendingStudent.pendingRequestId]: true,
												})
											}>
											Show name
										</ShowNaughtyNameButton>
									)}
								</p>
							</>
						)
					})()
				) : (
					<>
						{pendingStudents.some(s => s.nameIsNaughty) && (
							<div className="italic mb-2">
								We believe the names provided by some students may include inappropriate language.
								The potentially inappropriate names are visible on the computers of the students who
								submitted them.
							</div>
						)}
						<ul className="overflow-auto rounded">
							{pendingStudents.map(student => (
								<li
									key={student.pendingRequestId}
									className="px-2 pb-2 md:pt-2 odd:bg-primary-800 even:bg-primary-800/25">
									<div className="flex md:justify-between flex-wrap">
										<FlexSpan className="w-full md:w-fit">
											<b className="py-3 md:py-0">{getDisplayName(student)}</b>
											{student.nameIsNaughty && !shownNaughtyNames[student.pendingRequestId] && (
												<ShowNaughtyNameButton
													onClick={() =>
														setShownNaughtyNames({
															...shownNaughtyNames,
															[student.pendingRequestId]: true,
														})
													}>
													Show name
												</ShowNaughtyNameButton>
											)}
										</FlexSpan>
										<FlexSpan className="h-fit">
											<Button
												$small
												onClick={() => {
													setStudentsBeingAccepted([student])
												}}>
												Accept
											</Button>
											<Button
												variant="danger"
												$small
												onClick={() => {
													dispatch(
														sendMessage('PENDING_STUDENT_RESPONSE', {
															response: 'reject',
															pendingStudentRequestIds: [student.pendingRequestId],
														})
													)
												}}>
												Reject
											</Button>
										</FlexSpan>
									</div>
								</li>
							))}
						</ul>
					</>
				)}
			</CustomModal>
			<CustomModal
				className="text-base flex flex-col "
				isOpen={studentsBeingAccepted.length > 0}
				title={`Add ${
					studentsBeingAccepted.length === 1 ? getDisplayName(studentsBeingAccepted[0]) : 'students'
				} to class?`}
				onClose={() => setStudentsBeingAccepted([])}
				buttons={[
					<Button
						outline
						variant="danger"
						onClick={() => {
							dispatch(
								sendMessage('PENDING_STUDENT_RESPONSE', {
									response: 'accept',
									pendingStudentRequestIds: studentsBeingAccepted.map(
										student => student.pendingRequestId
									),
									addToClass: true,
								})
							)
							setStudentsBeingAccepted([])
						}}>
						Add to class
					</Button>,
					<Button
						onClick={() => {
							dispatch(
								sendMessage('PENDING_STUDENT_RESPONSE', {
									response: 'accept',
									pendingStudentRequestIds: studentsBeingAccepted.map(
										student => student.pendingRequestId
									),
									addToClass: false,
								})
							)
							setStudentsBeingAccepted([])
						}}>
						Just this mission
					</Button>,
				]}>
				{studentsBeingAccepted.length === 1 ? (
					<>
						Would you like to permanently add <b>{getDisplayName(studentsBeingAccepted[0])}</b> to
						the class, or just for this mission?
						{studentsBeingAccepted.length === 1 &&
							studentsBeingAccepted[0].nameIsNaughty &&
							!shownNaughtyNames[studentsBeingAccepted[0].pendingRequestId] && (
								<ShowNaughtyNameButton
									onClick={() =>
										setShownNaughtyNames({
											...shownNaughtyNames,
											[studentsBeingAccepted[0].pendingRequestId]: true,
										})
									}>
									Show name
								</ShowNaughtyNameButton>
							)}
					</>
				) : (
					<>
						<p className="mb-2">Students to add</p>
						<ul className="overflow-auto rounded">
							{studentsBeingAccepted.map(student => (
								<li
									key={student.pendingRequestId}
									className="p-2 odd:bg-primary-800 even:bg-primary-800/25">
									<FlexSpan>
										{getDisplayName(student)}
										{student.nameIsNaughty && !shownNaughtyNames[student.pendingRequestId] && (
											<ShowNaughtyNameButton
												onClick={() =>
													setShownNaughtyNames({
														...shownNaughtyNames,
														[student.pendingRequestId]: true,
													})
												}>
												Show name
											</ShowNaughtyNameButton>
										)}
									</FlexSpan>
								</li>
							))}
						</ul>
						<p className="mt-2">
							Would you like to permanently add these students to the class, or just for this
							mission?
						</p>
					</>
				)}
			</CustomModal>
		</>
	)
}

const FlexSpan = styled.span`
	display: flex;
	gap: var(--spacing);
`

const ShowNaughtyNameButton = styled.button`
	display: block;
	background: transparent;
	border: none;
	padding-left: 0;
	text-decoration: underline;

	&:hover {
		color: #999;
	}
`
