import React from 'react'
import { IoIosLock } from 'react-icons/io'
import {
	getStationUpgradeIndexForCurrentStation,
	getQuestionPhaseId,
	getStudentHasInitiatedUpgrades,
	isQuestionPhaseActive,
	getCurrentStation,
	isCurrentStationInQuestionPhase,
} from './selectors'
import Question from '../Question'
import { STATION_UPGRADE_ICONS } from './upgrades'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../basics'
import { sendMessage } from '../../store/stores/webSocket'
import { getStation, isMissionComplete as getIsMissionComplete } from '../../store/stores/general'
import { getStationActivity } from '../../utility/selectors'
import { useAdvanceTraining } from '../../store/selectors/jrPlusState/training'
import classnames from 'classnames'

export default function RenderStationUpgradeOverlay(): React$Node {
	const isStationActive = useSelector(state => {
		const station = getStation(state)
		return station ? getStationActivity(state)[station] : false
	})
	const isMissionComplete = useSelector(getIsMissionComplete)
	const currentStationIsInQuestionPhase = useSelector(isCurrentStationInQuestionPhase)
	if (!isMissionComplete && (isStationActive || currentStationIsInQuestionPhase)) {
		return <StationUpgradeOverlay />
	}
	return null
}

function StationUpgradeOverlay(): React$Node {
	const dispatch = useDispatch()
	const questionPhaseIsOver = !useSelector(isQuestionPhaseActive)
	const currentStation = useSelector(getCurrentStation)
	const questionPhaseIsInitiated = useSelector(getStudentHasInitiatedUpgrades)
	const upgradesInitiated = questionPhaseIsOver ? true : questionPhaseIsInitiated
	const questionPhaseId = useSelector(getQuestionPhaseId)
	const upgradeIndex = useSelector(getStationUpgradeIndexForCurrentStation)
	const advanceTraining = useAdvanceTraining()
	if (upgradeIndex === -1) {
		return null
	}
	const stationUpgradeIcons = STATION_UPGRADE_ICONS[currentStation]
	const totalUpgrades = stationUpgradeIcons?.icons.length
	const stationIsUpgradable = Boolean(totalUpgrades)

	return (
		<>
			<Question />
			<Overlay $block={!questionPhaseIsOver}>
				<TransformableContainer $minimized={upgradesInitiated} $total={totalUpgrades}>
					{stationIsUpgradable && (
						<>
							<div className="upgrades-wrapper">
								{stationUpgradeIcons.icons.map((upgrade, index) => {
									const IconRenderer = stationUpgradeIcons.IconRenderer
									const active = index < upgradeIndex
									return (
										<div
											key={upgrade.name}
											style={{ height: `${Math.floor(100 / totalUpgrades) - totalUpgrades}%` }}
											className="aspect-square space-y-8 pl-8 w-full">
											<svg
												viewBox="0 0 100 100"
												className="aspect-square"
												css="border: 2px solid aqua; border-radius: 16px"
												height="100%">
												<IconRenderer active={active} icon={upgrade.icon} />
												{!active && <IoIosLock size={30} color="white" />}
											</svg>
											<div
												className={classnames(
													'flex-1 bg-primary-darker rounded-br-[10px] rounded-tr-[10px] text-white p-6 text-left flex items-center whitespace-nowrap',
													upgradesInitiated && 'hidden'
												)}>
												{upgrade.name}
											</div>
										</div>
									)
								})}
							</div>
							<hr css="margin: 32px;" />
						</>
					)}
					<div className="initiate-upgrades-wrapper">
						<div css="width: fit-content; margin: auto;">
							<Button
								className="initiate-upgrades"
								css="font-size: 24px; white-space: nowrap; padding: 24px;"
								onClick={() => {
									if (advanceTraining) {
										advanceTraining()
									} else {
										dispatch(sendMessage('ADVANCE_QUESTION_PHASE', { questionPhaseId }))
									}
								}}>
								Initiate {stationIsUpgradable ? 'Upgrades' : 'Station'}
							</Button>
						</div>
					</div>
				</TransformableContainer>
			</Overlay>
		</>
	)
}

const TransformableContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	transform-origin: 0 0;
	left: 50%;
	top: 50%;
	height: 100%;
	transform: translate(-50%, -50%);
	${({ $minimized, $total }) =>
		$minimized &&
		`
        .initiate-upgrades-wrapper {
            visibility: hidden;
        }
        hr {
            visibility: hidden;
        }
      
        left: 0;
        top: 144px;
        transform: scale(${0.125 * $total});
        transition-duration: 1s;
        transition-delay: 500ms;
        transition-property: transform, left, top;
    `}
`

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	padding: 5%;
	position: absolute;

	${({ $block }) =>
		$block
			? `
	z-index: calc(var(--z-index-overlay) + 1);
	background-color: #00000dbb;
	`
			: `pointer-events: none;`}

	.upgrades-wrapper {
		display: flex;
		width: fit-content;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	.initiate-upgrades-wrapper {
		display: flex;
		width: fit-content;
		flex-direction: column;
		justify-content: space-between;
	}

	hr {
		border-color: aqua;
	}
`
