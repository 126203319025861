import { useCallback, useLayoutEffect } from 'react'
import { useEditor, type Editor } from '@tldraw/tldraw'
import { FRAME_LOCK_ID } from '../customShapes/CustomFrameShapeUtil'
import { CANVAS_STATUS } from '../constants'

/**
 * Locks the canvas viewport to be exactly 1200x900. WHen the canvas is resized, the camera is adjusted to fit the new viewport.
 */
export default function ViewportLock({ id, status }: { id: string, status: string }): React$Node {
	const editor = useEditor()

	const isLoading = status === CANVAS_STATUS.loading || status === CANVAS_STATUS['loading-ui']
	const snapCameraBackToOrigin = useSnapCameraBackToOrigin(editor, id)
	// Disables zoom/pan on the canvas
	useLayoutEffect(() => {
		// for mouse wheel or touch pad
		function disableScroll(e: WheelEvent) {
			e.preventDefault()
			e.stopImmediatePropagation()
		}
		const canvasRef = document.getElementById(id)?.querySelector('.tl-canvas')

		canvasRef?.addEventListener('wheel', disableScroll, { passive: false })
		return () => {
			canvasRef?.removeEventListener('wheel', disableScroll)
		}
	}, [id])

	useLayoutEffect(() => {
		const updateBounds = () => {
			if (isLoading) {
				return
			}
			snapCameraBackToOrigin()
		}

		window.addEventListener('resize', updateBounds)
		updateBounds()
		return () => {
			window.removeEventListener('resize', updateBounds)
		}
	}, [isLoading, snapCameraBackToOrigin])
	return null
}

/**
 * Snaps the camera back to the original frame of the canvas. Used when the canvas is resized, and can be used after a manual zoom or pan to return to the state of how it will be presented.
 * @param {TldrawApp} app
 * @param {stromg} documentId
 * @returns {void}
 */
export function useSnapCameraBackToOrigin(editor: Editor, canvasId: string): () => void {
	return useCallback(() => {
		const canvasRef = document.getElementById(canvasId)
		if (!canvasRef || !(canvasRef instanceof HTMLElement)) {
			return
		}
		const rect = canvasRef.getBoundingClientRect()
		const canvasBounds = {
			minX: rect.left,
			maxX: rect.left + rect.width,
			minY: rect.top,
			maxY: rect.top + rect.height,
			width: rect.width,
			height: rect.height,
		}
		const bounds = editor.getPageBoundsById(FRAME_LOCK_ID)
		if (!bounds) {
			return
		}
		const zoom = Math.min(canvasBounds.width / bounds.width, canvasBounds.height / bounds.height)
		editor.setCamera(0, 0, zoom)
	}, [editor, canvasId])
}
