import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { getWaitStatus } from '../../../../store/selectors/jrPlusState/waiters'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useTheme } from 'styled-components'
import { Row } from '../../../../components/basics'
import { PRIMARY_PURPLE_FAINT, APP_WHITE } from '../../../../constants/styles'
import DeltaClockProgressBar from '../../../../components/DeltaClock/ProgressBar'
import ActionRequired from './ActionRequired'
import { SHIP_WIDTH } from '../../constants'
import { useScreenScaledValues } from '../../hooks'
import { withSize } from 'react-sizeme'
import type { WaitStatus } from '../../../../store/selectors/jrPlusState/waiters'
import type { Coordinates, Alert as AlertData, RobotAction } from '../../../../types'
import type { AlertStatus } from '@mission.io/mission-toolkit'
import { ShipDimensionContext } from '../../context'
import { clamp } from '../../../../utility/functions'
import { MdClose } from 'react-icons/md'
import { FaExclamation } from 'react-icons/fa'
import { EngineeringPanelIcon } from '../../../../images/jrPlusPanel'
import { getCurrentRoomIdOfRobot } from '../../../../store/selectors/jrPlusState/decks'

const WIDTH = 80
const HEIGHT = 80
const DEFAULT_Y_OFFSET = -60
const ALERT_WIDTH = 400
const CONNECTOR_LENGTH = 50
const CONNECTOR_START_X = 40

const X_ROOM_BOUNDARY = SHIP_WIDTH - (ALERT_WIDTH + CONNECTOR_LENGTH)

const getFlippedEnd = (start, length, flipped): number => {
	return flipped ? start - length : start + length
}

export default function Alert({
	alert,
	point,
	data,
	action,
	isActiveAlert,
	pullAlertToFrontById,
}: {
	alert: AlertStatus,
	point: Coordinates,
	data: AlertData,
	action: ?RobotAction,
	isActiveAlert: boolean,
	pullAlertToFrontById: (id: string) => void,
}): React$Node {
	const isRobotInRoom = useSelector(getCurrentRoomIdOfRobot) === alert.roomId
	const waitingOn: ?Array<WaitStatus> = useSelector(state => getWaitStatus(state, alert.waitingOn))
	let [
		newX,
		newY,
		newWidth,
		newHeight,
		newConnectStart,
		newConnectLength,
		newDefaultAlertWidth,
		newDefaultYOffset,
		newXRoomBoundary,
	] = useScreenScaledValues([
		point.x,
		point.y,
		WIDTH,
		HEIGHT,
		CONNECTOR_START_X,
		CONNECTOR_LENGTH,
		ALERT_WIDTH,
		DEFAULT_Y_OFFSET,
		X_ROOM_BOUNDARY,
	])
	const flipDirection = newX > newXRoomBoundary
	const newConnectEnd = getFlippedEnd(newConnectStart, newConnectLength, flipDirection)
	const [yOffset, setYOffset] = useState(newDefaultYOffset)
	const [isOpen, setIsOpen] = useState(true)

	const alertId = alert.id
	const openAlert = useCallback(() => {
		setIsOpen(true)
		pullAlertToFrontById(alertId)
	}, [pullAlertToFrontById, alertId])

	useEffect(() => {
		if (isRobotInRoom) {
			openAlert()
		}
	}, [isRobotInRoom, openAlert])

	return (
		<div
			css={`
				position: absolute;
				top: ${newY}px;
				left: ${newX}px;
				transform: translate(-${newWidth / 2}px, -${newHeight / 2}px)
					scale(${clamp((newWidth / WIDTH) * 1.4, 0.1, 1)});
				opacity: 1;
			`}>
			<svg
				css={`
					color: ${APP_WHITE};
					overflow: visible;
					position: absolute;
				`}
				width={newWidth}
				height={newHeight}
				viewBox="0 0 80 80">
				<g style={{ pointerEvents: isOpen ? 'none' : 'all' }} onClick={openAlert}>
					{isOpen ? (
						<path
							css="padding: 0"
							d={`M${CONNECTOR_START_X} 8 L${CONNECTOR_START_X} -20 L${getFlippedEnd(
								CONNECTOR_START_X,
								CONNECTOR_LENGTH,
								flipDirection
							)} -20`}
							stroke={APP_WHITE}
							strokeWidth="8px"
							fill="none"
						/>
					) : null}
					{alert.investigated ? (
						<CircleProgressBarWrapper
							proportion={
								!waitingOn?.length
									? 1
									: waitingOn.reduce(
											(previous: number, current: WaitStatus) => previous + Number(current.passed),
											0
									  ) / waitingOn.length
							}>
							<g transform="translate(12 12) scale(0.55)">
								<EngineeringPanelIcon />
							</g>
						</CircleProgressBarWrapper>
					) : (
						<CircleProgressBarWrapper proportion={0}>
							<StyledExclamation x={12} y={12} width="65%" height="65%" />
						</CircleProgressBarWrapper>
					)}
				</g>
			</svg>
			{isOpen ? (
				<div
					className="alert"
					css={`
						position: absolute;
						width: ${newDefaultAlertWidth}px;
						transform: translate(
							${flipDirection ? newConnectEnd - newDefaultAlertWidth : newConnectEnd}px,
							${yOffset}px
						);
						transform-origin: top left;
						overflow-y: auto;
						pointer-events: none;
					`}>
					<AlertMessage
						className="rounded-lg"
						defaultYOffset={newDefaultYOffset}
						setYOffset={setYOffset}
						yCoordinate={newY}
						flipped={flipDirection}>
						<EventsDiv onClick={() => pullAlertToFrontById(alertId)}>
							<span>
								<h4>Alert!</h4>
								<div>{data.initial}</div>
							</span>
							<CloseAlert onClick={() => setIsOpen(false)} />
							{alert.investigatingTimerId && (
								<>
									<hr />
									<Row css="align-items: baseline;">
										<span style={{ padding: '6px 6px 6px 0' }}>Investigating</span>
										<DeltaClockProgressBar timerId={alert.investigatingTimerId} />
									</Row>
								</>
							)}
							{alert.fixingTimerId && (
								<>
									<hr />
									<Row css="align-items: center;">
										<span style={{ padding: '6px 6px 6px 0' }}>Fixing</span>
										<DeltaClockProgressBar timerId={alert.fixingTimerId} />
									</Row>
								</>
							)}
							{waitingOn && (
								<>
									<hr />
									<ActionRequired waitStatus={waitingOn} />
								</>
							)}
						</EventsDiv>
					</AlertMessage>
				</div>
			) : null}
		</div>
	)
}

type AlertProps = {
	children: React$Node,
	defaultYOffset: number,
	yCoordinate: number,
	setYOffset?: ?(newOffset: number) => void,
	className?: string,
	onClick?: ?() => void,
}

type WithSizeProps = { size: { height: number, width: number, position: { top: number } } }

function BaseAlertMessage({
	children,
	setYOffset,
	defaultYOffset,
	yCoordinate,
	size,
	className,
	onClick,
}: AlertProps & WithSizeProps) {
	const {
		height: alertHeight,
		// Listening to yPosition lets us know when we need to recalculate the y offset.
		position: { top: yPosition },
	} = size
	const { screenHeight } = useContext(ShipDimensionContext)
	useEffect(() => {
		if (setYOffset) {
			if (yCoordinate + defaultYOffset + alertHeight > screenHeight) {
				let newOffset = -screenHeight + yCoordinate + defaultYOffset + alertHeight
				setYOffset(-newOffset)
			} else if (yCoordinate !== defaultYOffset) {
				setYOffset(defaultYOffset)
			}
		}
	}, [screenHeight, alertHeight, setYOffset, yPosition, defaultYOffset, yCoordinate])

	return (
		<div
			className={className}
			onClick={() => {
				if (onClick) {
					onClick()
				}
			}}>
			{children}
		</div>
	)
}

export const AlertMessage: (props: AlertProps) => React$Node = withSize({
	monitorHeight: true,
	monitorPosition: true,
})(styled(BaseAlertMessage)`
	background-color: ${PRIMARY_PURPLE_FAINT};
	border: 3px solid ${APP_WHITE};
	color: ${APP_WHITE};
	padding: 4px 12px 12px 12px;
	width: fit-content;
	text-align: left;
	max-width: 100%;
	max-height: 100%;
	overflow-y: auto;
	position: revert !important;
	float: ${({ flipped }) => (flipped ? 'right' : 'left')};
	width: 100%;
	h4 {
		display: inline;
		margin: 0;
		padding-right: 4px;
	}
`)

const EventsDiv = styled.div`
	pointer-events: all;
`

const CloseAlert = styled(MdClose)`
	pointer-events: all;
	position: absolute;
	top: ${({ theme }) => theme.spacing};
	right: ${({ theme }) => theme.spacing};

	&:hover {
		cursor: pointer;
		color: rgba(255, 255, 255, 0.7);
	}
`

const StyledExclamation = styled(FaExclamation)`
	cursor: pointer;
`

const CircleProgressBarSvg = styled.svg`
	cursor: pointer;
`
/**
 * CircleProgressBarWrapper - wrap the children in a circular progress bard
 *
 * @param  {Object} props - the react props
 * @param  {number} props.proportion - the proportion (in range [0, 1]) of the task completed
 * @param  {children} props.children - the children component(s) of the react element
 *
 * @return {React$Node}
 */
function CircleProgressBarWrapper({
	proportion,
	children,
}: {
	proportion: number,
	children: React$Node,
}): React$Node {
	const { animatedProportion } = useSpring({ to: { animatedProportion: proportion } })
	const theme = useTheme()

	return (
		<CircleProgressBarSvg x="4" viewBox="0 0 40 40" width="90%" height="90%">
			<animated.path
				d="M20 5
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
				fill="none"
				stroke="white"
				strokeWidth="6"
			/>
			<animated.path
				d="M20 5
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
				fill="none"
				stroke={theme.success}
				strokeWidth="6"
				strokeDasharray={animatedProportion.to(proportion => `${proportion * 100}, 100`)}
			/>
			{children}
		</CircleProgressBarSvg>
	)
}
