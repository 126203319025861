import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useSelector } from 'react-redux'
import { getTimer, NO_PROGRESS_TIMER } from '../../store/selectors/sharedSelectors'

import type { Timer } from '@mission.io/mission-toolkit'
import { isPaused } from '../../store/stores/general'

const MILLISECONDS_IN_A_SECOND = 1000

export default function TimeLeft({ timerId }: { timerId: string }): React$Node {
	let timer: Timer = useSelector(state => getTimer(state, timerId)) || NO_PROGRESS_TIMER
	let spring = useSpring({
		to: { timeLeft: 0 },
		from: { timeLeft: timer.delayLeft },
		config: { duration: timer.isPaused ? Infinity : timer.delayLeft },
		reset: true,
	})
	return (
		<>
			Time Remaining:{' '}
			<AnimatedFragment>
				{spring.timeLeft.to(timeLeft => Math.ceil(timeLeft / MILLISECONDS_IN_A_SECOND))}
			</AnimatedFragment>{' '}
			seconds {timer.isPaused ? '(Paused)' : ''}
		</>
	)
}

export function TimeLeftNumbersOnly({
	timerId,
	customTimeFormatter,
}: {
	timerId: ?string,
	customTimeFormatter?: (secondsLeft: number) => string,
}): React$Node {
	const missionPause = useSelector(isPaused)
	let timer: Timer = useSelector(state => getTimer(state, timerId)) || EMPTY_TIMER
	let spring = useSpring({
		to: { timeLeft: 0 },
		from: { timeLeft: timer.delayLeft },
		config: { duration: timer.isPaused || missionPause ? Infinity : timer.delayLeft },
		reset: true,
	})

	return (
		<AnimatedFragment>
			{spring.timeLeft.to(timeLeft =>
				(customTimeFormatter ?? getClockFormat)(Math.ceil(timeLeft / MILLISECONDS_IN_A_SECOND))
			)}
		</AnimatedFragment>
	)
}
const EMPTY_TIMER: Timer = {
	isPaused: true,
	originalDelay: 0,
	delayLeft: 0,
	currentProgress: 0,
	id: 'undefined',
	tag: null,
}

const SECONDS_IN_A_MINUTE = 60
const FIRST_2_DIGIT_INTEGER = 10

function getClockFormat(totalSeconds: number) {
	const minutes = Math.floor(totalSeconds / SECONDS_IN_A_MINUTE)
	const seconds = totalSeconds % SECONDS_IN_A_MINUTE
	return (
		minutes.toString() + ':' + (seconds < FIRST_2_DIGIT_INTEGER ? '0' : '') + seconds.toString()
	)
}

/**
 * AnimatedFragment - a animated fragment component since react-spring does not implement one
 *
 * @param {Object} props - the react props
 * @param {children:React$Node} props.children - the children to put into the fragment
 *
 * @return {React$Node} - the animated fragment
 */
const AnimatedFragment = animated(function FragWrapper({
	children,
}: {
	children: React$Node,
}): React$Node {
	return <>{children}</>
})
